// extracted by mini-css-extract-plugin
export var layout = "landing-page-module--layout--Pd2a+";
export var banner = "landing-page-module--banner--0GHz+";
export var whyUs = "landing-page-module--why-us--vFGVu";
export var info = "landing-page-module--info--G1Qmb";
export var infoContainer = "landing-page-module--info-container--pFpeu";
export var infoDescriptions = "landing-page-module--info-descriptions--mwPb3";
export var infoLogo = "landing-page-module--info-logo--TVuaX";
export var infoTitle = "landing-page-module--info-title--SnA1t";
export var infoText = "landing-page-module--info-text--u7feO";
export var infoImages = "landing-page-module--info-images--ffpJg";
export var infoImage = "landing-page-module--info-image--M+ocl";
export var infoImageBcg = "landing-page-module--info-image-bcg--KvyOz";
export var awards = "landing-page-module--awards--Jp24t";
export var awardsTitle = "landing-page-module--awards-title--Iuz0m";
export var awardsList = "landing-page-module--awards-list--u4ETC";
export var awardsItem = "landing-page-module--awards-item--nj1go";
export var awardsCard = "landing-page-module--awards-card--DRx-B";
export var awardsText = "landing-page-module--awards-text--dnBqd";
export var awardsLogo = "landing-page-module--awards-logo--yZYDT";
export var about = "landing-page-module--about--p6jt3";
export var videoEye = "landing-page-module--video-eye--d2wPp";
export var workList = "landing-page-module--work-list--zGvuM";
export var imageSection = "landing-page-module--image-section--Tp-QP";
export var imageSectionText = "landing-page-module--image-section-text--QP0t0";
export var imageSectionTitle = "landing-page-module--image-section-title--BqPTf";
export var imageSectionContainer = "landing-page-module--image-section-container--4mjO6";
export var imageSectionImage = "landing-page-module--image-section-image--138Cx";