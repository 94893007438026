import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { StaticImage } from 'gatsby-plugin-image';

import {
    layout,
    banner,
    about,
    videoEye,
    whyUs,
    info,
    infoContainer,
    infoDescriptions,
    infoLogo,
    infoTitle,
    infoText,
    infoImages,
    infoImage,
    infoImageBcg,
    awards,
    awardsTitle,
    awardsList,
    awardsItem,
    awardsCard,
    awardsText,
    awardsLogo,
    workList,
    imageSection,
    imageSectionContainer,
    imageSectionImage,
    imageSectionTitle,
    imageSectionText,
} from './landing-page.module.scss';
import { navLinks } from '../config/nav-links';
import { IWork } from '../models/work.model';
import { IAward } from '../models/awards.model';

import MainLayout from '../layouts/main-layout';
import AboutUs from '../components/organisms/about-us';
import HomeVideoBanner from '../components/organisms/home-video-banner';
import Contact from '../components/organisms/contact';
import WhyUs from '../components/organisms/why-us';
import Title from '../components/atoms/title';
import Works from '../components/organisms/works';
import AwardCard from '../components/molecules/award-card';

const logo = '../assets/images/landing-page/logo.png';
const newspaper = '../assets/images/landing-page/newspaper.png';
const newspaperBcg = '../assets/images/landing-page/newspaper-bcg.png';
const image = '../assets/images/landing-page/image.png';

interface ILandingPageProps {
    readonly data: {
        landing: {
            works: IWork[];
            awards: IAward[];
        };
    };
}

const LandingPage: React.FC<ILandingPageProps> = ({ data }) => {
    const { landing } = data;
    const { t } = useI18next();
    const amountOfOtherSections = landing.awards.length > 0 ? 6 : 5;
    const sectionsToScrollAmount = amountOfOtherSections + landing.works.length;

    return (
        <MainLayout
            isVisibleHeaderBg={false}
            className={layout}
            isLanding={true}
            sectionsToScrollAmount={sectionsToScrollAmount}
        >
            <HomeVideoBanner className={banner} isLanding={true} />
            <WhyUs className={whyUs} id={t(navLinks.whyUs.urlIdKey)} isLanding={true} />
            <div className={info}>
                <div className={infoContainer}>
                    <div className={infoDescriptions}>
                        <StaticImage
                            src={logo}
                            alt={t('logo.fcb.alt')}
                            quality={100}
                            className={infoLogo}
                        />
                        <Title className={infoTitle}>
                            <span>{t('landing.info.title')}</span>
                        </Title>
                        <p className={infoText}>{t('landing.info.text')}</p>
                    </div>

                    <div className={infoImages}>
                        <StaticImage src={newspaper} className={infoImage} alt="" quality={100} />
                        <StaticImage
                            src={newspaperBcg}
                            className={infoImageBcg}
                            alt=""
                            quality={100}
                        />
                    </div>
                </div>
            </div>
            {landing.awards.length > 0 && (
                <div className={awards}>
                    <Title className={awardsTitle}>{t('landing.awards.title')}</Title>
                    <ul className={awardsList}>
                        {landing.awards.map((award) => {
                            return (
                                <li className={awardsItem} key={award.awardId}>
                                    <AwardCard
                                        award={award}
                                        className={awardsCard}
                                        logoClassName={awardsLogo}
                                        textClassName={awardsText}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}

            <AboutUs className={about} id={t(navLinks.about.urlIdKey)} isLanding={true} />
            <div className={imageSection}>
                <div className={imageSectionContainer}>
                    <Title className={imageSectionTitle}>
                        {t('landing.images.title')} <br />
                        <span className={imageSectionText}>{t('landing.images.text')}</span>
                    </Title>
                </div>
                <StaticImage
                    src={image}
                    className={imageSectionImage}
                    alt={t('logo.xsb.alt')}
                    quality={100}
                />
            </div>
            {landing.works.length > 0 && <Works className={workList} works={landing.works} />}
            <Contact className={videoEye} id={t(navLinks.contact.urlIdKey)} isLanding={true} />
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        landing {
            awards {
                ...awardFields
            }
            works {
                ...workFields
            }
        }
    }
`;

export default LandingPage;
