// extracted by mini-css-extract-plugin
export var work = "works-module--work--PuWUw";
export var workWithAwards = "works-module--work-with-awards--PRMK-";
export var workContainer = "works-module--work-container--VS+oD";
export var workDescriptionsLeft = "works-module--work-descriptions-left--xs67z";
export var workDescriptionsRight = "works-module--work-descriptions-right--94ern";
export var workImagesLeft = "works-module--work-images-left--hrtS+";
export var workImagesRight = "works-module--work-images-right--jceqB";
export var workImage = "works-module--work-image--7i34e";
export var workImageBcg = "works-module--work-image-bcg--hOQTE";
export var workTitle = "works-module--work-title--2sZcU";
export var workClient = "works-module--work-client--uPqC0";
export var workClientName = "works-module--work-client-name--EUlSM";
export var workLead = "works-module--work-lead--0O98L";
export var workVideo = "works-module--work-video--Z3scr";
export var workVideoInner = "works-module--work-video-inner--tUNhD";
export var awards = "works-module--awards--6A1nj";
export var awardsHeading = "works-module--awardsHeading--9bCOo";
export var awardsList = "works-module--awards-list--lVCYQ";
export var awardsSlider = "works-module--awards-slider--yMwjB";
export var awardsCard = "works-module--awards-card--Bm8P9";
export var awardsLogo = "works-module--awards-logo--3vU+Y";