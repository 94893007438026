import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    work as workClass,
    workWithAwards as workWithAwardsClass,
    workContainer as workContainerClass,
    workDescriptionsLeft as workDescriptionsLeftClass,
    workDescriptionsRight as workDescriptionsRightClass,
    workImage as workImageClass,
    workImageBcg as workImageBcgClass,
    workImagesLeft as workImagesLeftClass,
    workImagesRight as workImagesRightClass,
    workTitle as workTitleClass,
    workClient as workClientClass,
    workClientName as workClientNameClass,
    workLead as workLeadClass,
    workVideo as workVideoClass,
    workVideoInner as workVideoInnerClass,
    awards,
    awardsHeading,
    awardsList,
    awardsSlider,
    awardsCard,
    awardsLogo,
} from './works.module.scss';
import { IWork } from '../../models/work.model';
import { useTranslation } from '../../hooks/use-translation';
import useMedia from '../../hooks/use-media';
import { translationKeys } from '../../config/translation-keys';
import { relations } from '../../config/relations';

import Title from '../atoms/title';
import AwardCard from '../molecules/award-card';
import Slider from '../hoc/slider';
import VideoPlayer from '../molecules/video-player';

interface IWorksProps {
    className?: string;
    works: IWork[];
}

const Works: React.FC<IWorksProps> = ({ className = '', works }) => {
    const { t } = useI18next();

    return (
        <ul className={className}>
            {works.map((work, idx) => {
                const thumbnailHorizontal = useMedia({
                    media: work.media,
                    relation: relations.thumbnailHorizontal,
                });

                const thumbnailBackground = useMedia({
                    media: work.media,
                    relation: relations.thumbnailBackground,
                });

                const { title, clientContent, leadLanding, videoUrl } = useTranslation(
                    work,
                    translationKeys.workCard
                );

                return (
                    <li
                        key={work.workId}
                        className={
                            work.awards && work.awards.length > 0 ? workWithAwardsClass : workClass
                        }
                    >
                        <div className={workContainerClass}>
                            <div
                                className={
                                    idx % 2 === 0 ? workImagesLeftClass : workImagesRightClass
                                }
                            >
                                {videoUrl && (
                                    <div className={workVideoClass}>
                                        <VideoPlayer
                                            url={videoUrl}
                                            className={workVideoInnerClass}
                                        />
                                    </div>
                                )}

                                {!videoUrl && thumbnailHorizontal.image && (
                                    <GatsbyImage
                                        className={workImageClass}
                                        image={thumbnailHorizontal.image}
                                        alt={thumbnailHorizontal.alt || ''}
                                    />
                                )}

                                {!videoUrl &&
                                    !thumbnailHorizontal.image &&
                                    thumbnailHorizontal.url && (
                                        <img
                                            src={thumbnailHorizontal.url}
                                            className={workImageClass}
                                            alt={thumbnailHorizontal.alt}
                                        />
                                    )}

                                {thumbnailBackground.image && (
                                    <GatsbyImage
                                        className={workImageBcgClass}
                                        image={thumbnailBackground.image}
                                        alt={thumbnailBackground.alt || ''}
                                    />
                                )}

                                {!thumbnailBackground.image && thumbnailBackground.url && (
                                    <img
                                        src={thumbnailBackground.url}
                                        className={workImageBcgClass}
                                        alt={thumbnailBackground.alt}
                                    />
                                )}
                            </div>
                            <div
                                className={
                                    idx % 2 === 0
                                        ? workDescriptionsRightClass
                                        : workDescriptionsLeftClass
                                }
                            >
                                <Title size={'big'} className={workTitleClass}>
                                    <span>{title}</span>
                                </Title>
                                {clientContent && (
                                    <p className={workClientClass}>
                                        {t('landing.works.client')}{' '}
                                        <span className={workClientNameClass}>{clientContent}</span>
                                    </p>
                                )}
                                <p className={workLeadClass}>{leadLanding}</p>
                            </div>
                        </div>
                        {work.awards && work.awards.length > 0 && (
                            <div className={awards}>
                                <h3 className={awardsHeading}>{t('landing.awards.title')}</h3>
                                <Slider className={awardsSlider} {...sliderOptions}>
                                    {work.awards.map((award) => {
                                        return (
                                            <div key={award.awardId}>
                                                <AwardCard
                                                    award={award}
                                                    logoClassName={awardsLogo}
                                                />
                                            </div>
                                        );
                                    })}
                                </Slider>
                                <ul className={awardsList}>
                                    {work.awards.map((award) => {
                                        return (
                                            <li key={award.awardId}>
                                                <AwardCard
                                                    award={award}
                                                    className={awardsCard}
                                                    logoClassName={awardsLogo}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

const sliderOptions = {
    slidesPerView: 3,
    spaceBetween: 10,
    autoplay: { delay: 1500 },
    breakpoints: {
        1025: {
            slidesPerView: 4,
        },
    },
};

export default Works;
